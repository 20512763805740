import React from 'react';

const Portfolio = () => {
  return (
    <div className="min-h-screen p-8 md:p-12 overflow-hidden">
      {/* Main Section */}
      <div className="flex justify-between text-3xl lg:text-6xl text-[#C2B080] font-body">
        <h2>betsy keiser</h2>
        <h2 className="hidden md:inline">creative portfolio</h2>
      </div>
      <div className="md:relative flex self-center flex-col sm:items-center justify-center font-hero">
        <img className="min-w-sm h-auto pt-6 pb-4 lg:max-w-2xl lg:h-auto" src={"./img/43790031.JPG"} alt="photograph of buildings in a city with light reflections"></img>
        <h1 className="text-6xl lg:absolute lg:text-[10rem] sm:max-md:leading-none text-[#C2B080] text-center">website under construction</h1>
      </div>
    </div>
  );
};

export default Portfolio;
